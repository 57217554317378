<template>
  <div class="main page" v-if="this.visible">
    <div class="top-header">
      <h1 class="page-title">Grow Diary</h1>
    </div>
    <div class="page-content">
      <div class="content-intro">
        <v-row>
          <v-col cols="4">
            <Character
              :characterLevel="characterLevel"
              :starLevel="starLevel"
            />
          </v-col>
          <v-col cols="8">
            <h6>Grow Diary에 오신 것을 환영합니다.</h6>
            <p>
              성장호르몬 치료 과정 동안, 여러분을 응원하고 지원할 수 있는
              성장일기를 아이와 함께 사용하는 것을 추천합니다.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col cols="12" class="urgent-notice">
            알림톡 기능 이상으로 발송이 일시 중지되었습니다.<br />
            조속한 시일 내 정상화 하겠습니다. 불편드려 죄송합니다.
          </v-col> -->
        </v-row>
        <!-- <v-btn
          color="primary"
          class="w-100"
          depressed
          large
          @click="PageTo('report')"
        >
          성장일기 리포트
        </v-btn> -->
      </div>
      <div class="grey-card mt-4 main-summary">
        <v-row>
          <!-- 투약 기록 -->
          <InjectionRecord
            :startDay="startDay"
            :endDay="endDay"
            :injectedDaysCount="injectedDaysCount"
            :expectedDaysCount="expectedDaysCount"
          />
          <v-divider vertical></v-divider>
          <!-- 성장 기록 -->
          <GrowthRecord
            :increaseHeight="increaseHeight"
            :startedMeasureDay="startedMeasureDay"
          />
        </v-row>
      </div>
      <div class="main-menu mt-4">
        <div class="menu-left">
          <div class="banner myinfo" @click="clickMyInfo">
            나의 정보
            <p>
              처음 사용하시는 분은<br />
              나의 정보를 먼저<br />
              입력해주세요!
            </p>
          </div>
          <div class="banner injection" @click="PageTo('injection')">
            싸이젠 투약 <br />다이어리
          </div>
          <div class="banner alert" @click="PageTo('alert')">알림 설정</div>
        </div>
        <div class="menu-right">
          <div class="banner grow" @click="PageTo('grow')">나의 성장 기록</div>
          <div class="banner mission" @click="PageTo('mission')">성장 미션</div>

          <div class="banner report" @click="PageTo('report')">
            성장일기 리포트
          </div>
        </div>
      </div>
    </div>
    <!-- 나의 정보 없는 사용자에게 알림 -->
    <FirstUserPopup ref="FirstUserPopup" />
    <!-- <NoticePopup ref="NoticePopup" /> -->
    <!-- <NoticePopup2 ref="NoticePopup2" /> -->
    <!-- 캐릭터 진화 알림 -->
    <LevelUpPopup
      :characterLevel="characterLevel"
      :starLevel="starLevel"
      @clickCloseBtn="clickLevelUpPopupCloseBtn"
      ref="LevelUpPopup"
    />

    <!-- 스티커 발급 알림 -->
    <IssuedStickerPopup
      :characterLevel="characterLevel"
      :starLevel="starLevel"
      @clickCloseBtn="clickStickerPopupCloseBtn"
      ref="IssuedStickerPopup"
    />
  </div>
</template>

<script>
// import NoticePopup from "@/components/common/popup/Notice_Popup.vue";
// import NoticePopup2 from "@/components/common/popup/Notice_Popup2.vue";
import FirstUserPopup from "@/components/common/popup/FirstUser_Popup.vue";
import LevelUpPopup from "@/components/common/popup/LevelUp_Popup.vue";
import IssuedStickerPopup from "@/components/common/popup/IssuedSticker_Popup.vue";
import moment from "moment";
import { postCheckUserKey } from "@/api/checkUserKey";
import { getInjectionLog } from "@/api/injectionLog";
import { getBodyInfo } from "@/api/bodyInfo";
import { getLevelPopup, postLevelPopup } from "@/api/levelPopup";
import InjectionRecord from "@/components/main/InjectionRecord.vue";
import GrowthRecord from "@/components/main/GrowthRecord.vue";
import Character from "@/components/common/growRecord/Character.vue";
import store from "@/store/index";
import Cookies from "js-cookie";

export default {
  components: {
    // NoticePopup,
    // NoticePopup2,
    FirstUserPopup,
    LevelUpPopup,
    IssuedStickerPopup,
    InjectionRecord,
    GrowthRecord,
    Character,
  },
  data() {
    return {
      visible: false,
      commonPopupStatus: false,
      moment,
      characterLevel: null,
      starLevel: null,

      // 투약 기록
      startDay: null,
      endDay: null,
      injectedDaysCount: null,
      expectedDaysCount: null,
      // 성장 기록
      startedMeasureDay: null,
      increaseHeight: null,
    };
  },
  mounted() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("KAKAOTALK") < 0) {
      this.visible = false;
      alert("허용되지 않는 접근입니다");
      console.log("잘못접근");
      return;
    }
    this.visible = true;
    this.getUser();
    this.deleteQueryString();
    // if (Cookies.get("isNoticePopup")) {
    //   return;
    // }
    // this.$refs.NoticePopup.Open();
    // Cookies.set("isNoticePopup", true, { expires: 1000 });
  },
  methods: {
    onchangeOpenCloseStatus(value) {
      this.commonPopupStatus = value;
    },
    onchangeFirstInjectionDate(value) {
      this.firstInjectionDate = value;
    },
    clickMyInfo() {
      // this.$refs.NoticePopup2.Open();
      // return;
      this.$router.push({
        name: "myinfo",
        params: {
          userExists: true,
        },
      });
    },
    async levelUpPopupClose() {
      const userIndex = this.$store.getters.getUserIndex;
      const res = await postLevelPopup({ userIndex });
      console.log(res);
    },
    clickLevelUpPopupCloseBtn() {
      this.levelUpPopupClose();
    },
    clickStickerPopupCloseBtn() {
      this.levelUpPopupClose();
    },
    deleteQueryString() {
      history.replaceState({}, null, location.pathname);
    },
    PageTo(url) {
      this.$router.push(url);
    },
    bindDataSet(injectionLog, bodyInfo) {
      // 투약기록 바인딩
      this.startDay = injectionLog.startDay;
      this.endDay = injectionLog.endDayToNow;
      this.injectedDaysCount = injectionLog.injectedDaysCount;
      this.expectedDaysCount = injectionLog.expectedDaysCountToNow;
      // 성장기록 바인딩
      this.startedMeasureDay = bodyInfo.startedMeasureDay;
      this.increaseHeight = bodyInfo.increaseHeight;
    },
    checkLevelUpPopup(res) {
      if (res) {
        this.characterLevel = res.user_character_level;
        this.starLevel = res.user_star_level;
        this.isLevelUp = res.is_level_up;
        if (res.is_popup === "N") {
          if (res.is_level_up === "Y") {
            this.$refs.LevelUpPopup.Open();
          } else {
            this.$refs.IssuedStickerPopup.Open();
          }
        }
      }
    },
    async getUser() {
      // const userKey = this.$route.query.userkey;
      const userKey = store.getters.getUserKey;
      const reqData = {
        data: {
          userKey,
        },
      };
      const { result } = await postCheckUserKey(reqData);
      console.log(result);
      if (result.error === "-1") {
        this.$refs.FirstUserPopup.Open();
        store.dispatch("changeUserIndex", null);
      } else {
        //  user_index 값 state 에 저장
        store.dispatch("changeUserIndex", result.user_index);

        const reqData = {
          userIndex: result.user_index,
          targetYear: moment().format("YYYY"),
          targetMonth: moment().format("M"),
        };
        const [injectionLog] = await getInjectionLog(reqData);
        const [bodyInfo] = await getBodyInfo(reqData);
        // console.log(injectionLog, bodyInfo);
        this.bindDataSet(injectionLog, bodyInfo);
        // 레벨업 팝업 조회
        const [res] = await store.dispatch("getLevelPopupData");
        // const res = await getLevelPopup(reqData);
        console.log(res);
        this.checkLevelUpPopup(res);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.main {
  .main-summary {
    > .row {
      align-items: start;
      margin-bottom: 0 !important;
    }
    .col-title {
      font-weight: 700;
      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
    .grow-num-wrap {
      display: inline-block;
      width: 100%;
      text-align: center;
      .grow-num {
        font-size: 35px;
        font-weight: 700;
        color: $secondary;
        .v-icon {
          color: $secondary;
          margin-right: -5px;
        }
        span {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
  .main-menu {
    display: table;
    width: 100%;
    .banner {
      width: 100%;
      height: 129px;
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 10px;
      padding: 15px;
      border-radius: 18px;
      &.myinfo {
        background-image: url(~@/assets/images/menu/myinfo.png);
        background-size: 90px;
        background-position: bottom right;
        background-color: #d0eaea;
        p {
          font-size: 10px;
          color: #555555;
          line-height: 11px;
          margin-top: 5px;
        }
      }
      &.injection {
        background-image: url(~@/assets/images/menu/injection.png);
        background-size: 90px;
        background-position: bottom right;
        background-color: #ffe3bd;
      }
      &.grow {
        background-image: url(~@/assets/images/menu/grow.png);
        background-size: 90px;
        background-position: bottom right;
        background-color: #e9ebfc;
      }
      &.mission {
        background-image: url(~@/assets/images/menu/mission.png);
        background-size: 90px;
        background-position: bottom right;
        background-color: #fadede;
      }
      &.alert {
        background-image: url(~@/assets/images/menu/alert.png);
        background-size: 90px;
        background-position: bottom right;
        background-color: #e0edd3;
      }
      &.report {
        background-image: url(~@/assets/images/menu/report.png);
        background-size: 90px;
        background-position: bottom right;
        background-color: #ffefb7;
      }
    }
    .menu-left {
      display: table-cell;
      width: 50%;
      padding-right: 5px;
      .banner {
        height: 90px;
      }
    }
    .menu-right {
      display: table-cell;
      width: 50%;
      padding-left: 5px;
      .banner {
        height: 90px;
      }
    }
  }
}
</style>
