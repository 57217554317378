<template>
  <v-dialog v-model="dialog" content-class="popup level-up">
    <div class="popup-content">
      <div class="character-wrap">
        <div class="stars">
          <img
            v-for="index in starLevel"
            :key="index"
            class="star"
            src="@/assets/images/character/star.png"
            alt=""
          />
        </div>
        <img
          class="character"
          :src="
            require(`@/assets/images/character/character_lev${
              characterLevel || 1
            }.png`)
          "
          alt=""
        />
      </div>
      <h6 class="c-sec text-center">축하합니다!</h6>
      <p>
        성장미션 스티커를 모아 젠(Zen)이 성장했습니다. 젠이 쑥쑥 클 수 있도록
        스티커를 잘 모아주세요.
      </p>
      <v-btn color="secondary" class="w-100" depressed @click="Close">
        확인
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    characterLevel: {
      type: Number,
      default: 1,
    },
    starLevel: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
    Close() {
      this.dialog = false;
      this.$emit("clickCloseBtn");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.level-up {
}
</style>