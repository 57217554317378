import { checkUserKey } from "./index";

export const postCheckUserKey = (userData) => {
  return checkUserKey
    .post("", userData)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
