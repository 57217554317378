<template>
  <v-col v-if="startedMeasureDay" cols="6" class="">
    <div class="col-title mb-2 ml-1">
      성장 기록
      <span class="c-sec ml-1">{{ startedMeasureDay }}</span>
    </div>
    <div class="grow-num-wrap">
      <div class="grow-num">
        <v-icon v-if="Number(increaseHeight) >= 0">mdi-plus</v-icon>
        <v-icon v-else>mdi-minus</v-icon>
        {{ computedIncreaseHeight }}
        <span>cm</span>
      </div>
    </div>
  </v-col>
  <v-col v-else cols="6" class="progress-text">
    <div class="col-title mb-2 ml-1">성장 기록</div>
    <v-row>
      <v-col cols="12" class="inject-label">
        <div class="c-prm">
          얼마나 성장하고 있는지 키와 몸무게를 입력해 주세요.
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    startedMeasureDay: {
      type: String,
      default: null,
    },
    increaseHeight: {
      type: String,
      default: null,
    },
  },
  computed: {
    computedIncreaseHeight() {
      return Number(this.increaseHeight) < 0
        ? Math.abs(this.increaseHeight)
        : this.increaseHeight;
    },
  },
};
</script>
