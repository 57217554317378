<template>
  <div>
    <div class="stars">
      <img
        v-for="index in starLevel"
        :key="index"
        class="star"
        src="@/assets/images/character/star.png"
        alt=""
      />
    </div>
    <img
      class="character"
      :src="
        require(`@/assets/images/character/character_lev${
          characterLevel || 1
        }.png`)
      "
      alt=""
    />
  </div>
</template>

<script>
export default {
  props: {
    starLevel: {
      type: Number,
      default: 0,
    },
    characterLevel: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
</style>