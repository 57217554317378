<template>
  <v-dialog v-model="dialog" content-class="popup level-up">
    <div class="popup-content">
      <div class="content-intro">
        <v-row>
          <v-col cols="4">
            <div class="stars">
              <img
                v-for="index in starLevel"
                :key="index"
                class="star"
                src="@/assets/images/character/star.png"
                alt=""
              />
            </div>
            <img
              class="character"
              :src="
                require(`@/assets/images/character/character_lev${
                  characterLevel || 1
                }.png`)
              "
              alt=""
            />
          </v-col>
          <v-col cols="8">
            <p>
              한달동안 싸이젠 투약을 잘 하셨습니다. 젠(Zen) 성장에 필요한
              스티커를 받았습니다.
            </p>
          </v-col>
        </v-row>
      </div>
      <p class="text-center">지난 달 투약기록을 확인해 볼까요?</p>
      <v-btn
        color="secondary"
        class="w-100"
        depressed
        @click="PageTo('mission_detail')"
      >
        투약 기록 확인하기
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    characterLevel: {
      type: Number,
      default: 1,
    },
    starLevel: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
    Close() {
      this.dialog = false;
    },
    PageTo(url) {
      this.$router.push({
        name: url,
        params: {
          targetYear: this.$moment().format("YYYY"),
          targetMonth: this.$moment().subtract(1, "month").format("M"),
        },
      });
      this.$emit("clickCloseBtn");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.level-up {
}
</style>