<template>
  <v-col v-if="startDay" cols="6" class="progress-text">
    <div class="col-title mb-2">
      투약 기록
      <span class="c-prm ml-1">{{ startDay }} - {{ endDay }}</span>
    </div>
    <v-row>
      <v-col cols="6" class="c-prm inject-num">{{ injectedDaysCount }}</v-col>
      <v-col cols="6" class="planned-num">/ {{ expectedDaysCount }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="inject-label"
        ><span class="c-prm">투여</span></v-col
      >
      <v-col cols="6" class="planned-label"><span>예정</span></v-col>
    </v-row>
  </v-col>
  <v-col v-else cols="6" class="progress-text">
    <div class="col-title mb-2">투약 기록</div>
    <v-row>
      <v-col cols="12" class="inject-label">
        <div class="c-prm">
          싸이젠 주사를 잊지 않도록 투약 정보를 기록해 주세요.
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    startDay: {
      type: String,
      default: null,
    },
    endDay: {
      type: String,
      default: null,
    },
    injectedDaysCount: {
      type: Number,
      default: null,
    },
    expectedDaysCount: {
      type: Number,
      default: null,
    },
  },
};
</script>