<template>
  <v-dialog v-model="dialog" content-class="popup" persistent>
    <div class="popup-content">
      <p>
        처음 이용하시나요? <br />
        나의 정보를 입력하고 서비스를 이용해 보세요!
      </p>
      <!-- <p>
        처음 이용하시나요? <br />
        잠시 신규가입이 중단되었습니다. <br />
        불편을 드려 죄송합니다.
      </p> -->
      <!--  -->
      <v-btn
        color="secondary"
        class="w-100"
        depressed
        @click="PageTo('myinfo')"
      >
        나의 정보 입력하기
      </v-btn>
      <!-- <v-btn color="secondary" class="w-100" depressed @click="disableSignup">
        확인
      </v-btn> -->
    </div>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
    Close() {
      this.dialog = false;
    },
    PageTo(url) {
      this.$router.push(url);
    },
    disableSignup() {
      this.dialog = false;
      // this.$router.push("/");
      window.location.href = "/";
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/octavia-design-system/variables/_colors.scss";
.popup.common {
}
</style>